<template>
  <div class="stats">
    <b-card title="Card Title" no-body class="card-NoBorder">
      <b-card-header header-tag="nav" class="statsCard-Header">
        <b-nav card-header pills>
          <b-dropdown :text="this.id + ' Season'">
            <b-dropdown-item
              v-on:click="changeYear"
              v-for="(seasons, index) in noData"
              :key="index"
              :title="seasons.code"
              :year="seasons.code"
              >{{ seasons.desc }}</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown v-if="stats.stats === ''" :text="'Report'">
            <b-dropdown-item>No Stats</b-dropdown-item>
          </b-dropdown>
          <b-dropdown v-else :text="data.full_name">
            <b-dropdown-item
              v-on:click="pickStat"
              :title="'-ooms-oom-' + stats.ooms.ooms_entry.code"
              >{{ stats.ooms.ooms_entry.full_name }}</b-dropdown-item
            >
            <b-dropdown-item
              v-on:click="pickStat"
              v-for="(stats, index) in stats.stats.stats_entry"
              :key="index"
              :title="'-stats-stats-' + stats.code"
              v-show="stats.show_app == 'B' || stats.show_app == 'W'"
              >{{ stats.full_name }}</b-dropdown-item
            >
          </b-dropdown>
        </b-nav>
      </b-card-header>
      <div class="title">
        <h3>{{ data.short_name }} {{ data.season_name }}</h3>
        <h3>
          {{ data.last_tournament }}
        </h3>
        <p>Last Day Of Play {{ data.date_valid }}</p>
      </div>
      <StatsTable v-if="stats.length === 0" :id="id" :config="config" />
      <StatsTable v-else :id="id" :stat="stat" :data="data" :config="config" />
    </b-card>

			<!--   <iframe style="width:100%" src="https://info.alpstourgolf.com/tic/tmtic.cgi" width="944" height="1150" frameborder="0"></iframe> -->
  </div>
</template>

<script>
import StatsTable from "@/components/statsTable.vue";
import apiCall from "@/services/api.js";
export default {
  name: "stats",
  components: {
    StatsTable,
  },
  props: ["config"],
  data() {
    return {
      stats: [],
      id: "",
      stat: "",
      data: [],
      tm_params: [],
    };
  },
  computed: {
    noData: function() {
      return this.tm_params.seasons.seasons_entry
        .slice()
        .reverse()
        .slice(0, 4);
    },
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (this.id = id), this.callTwo(id), this.callThree(id, this.stat);
    },
    pickStat: function(event) {
      var stat = event.target.getAttribute("title");
      return (this.stat = stat), this.callThree(this.id, stat);
    },
  callOne() {
    apiCall
        .tmParamsBase()
        .then(({ data }) => {
          this.tm_params = data;
          this.id = data.tm_params.season_code;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        }),
      console.log("one")
    },
   callTwo() {
     console.log(this.id)
      apiCall
        .tmStats(this.id)
        .then(({ data }) => {
          this.stats = data;
          this.stat = "-ooms-oom-" + data.ooms.ooms_entry.code;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        }),
      console.log("two");
    },
    callThree() {
       apiCall
        .stat(this.id, this.stat)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        }),
      console.log("three"); 
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParamsBase();
      this.tm_params = first_call.data;
      this.id = first_call.data.tm_params.season_code;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmStats(this.id);
      this.stats = second_call.data;
      this.stat = "-ooms-oom-" + second_call.data.ooms.ooms_entry.code;
      this.loading = false;
      // Third call, await for call to return
      let third_call = await apiCall.stat(this.id, this.stat);
      this.data = third_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>
  async mounted() {
    this.callOne();
    setTimeout(() => {
      this.callTwo();
    }, 500);
    setTimeout(() => {
      this.callThree();
    }, 1000);
  },
<style scoped>
  ::v-deep td {
    font-size: 1rem;
  }
.title {
  text-align: center;
  background-color: #263056;
  color: white;
  text-transform: capitalize;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
.stats {
  padding-top: 50px;
}
::v-deep .btn {
  background-color: #263056;
}
.statsCard-Header {
  background-color: transparent;
  border-bottom: 0px solid;
}
.card-NoBorder {
  border: 0px solid;
}
div#__BVID__56 {
  padding-right: 10px;
}
.dropMenu {
  text-align: center;
  padding-top: 10px;
}
.dropdown {
  width: 20%;
  padding: 0 10px;
}

::v-deep .btn {
  background-color: #263056;
  color: white;
  text-align: left;
  border-radius: 0;
}

::v-deep .btn:after {
  float: right;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  .dropdown {
    width: 50%;
  }
}
@media only screen and (max-width: 480px) {
  ::v-deep .dropdown-menu {
    left: -1em !important;
    text-align: center;
  }
}
</style>
